import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import * as style from './FullWidthImage.module.css'
import { CSSTransition } from 'react-transition-group';


export default function FullWidthImage(props) {
  const {
    height = '100vh',
    img,
    imgRespo,
    title,
    subheading,
    imgPosition = "center center",
    imgPositionRespo = "center bottom",
  } = props;


  return (
    <React.Fragment>
      <div className={`margin-top-0 full-height d-grid ${style.header}`}>
        <div className={style.imageShadow} />
        <CSSTransition
          in={true}
          timeout={600}
          unmountOnExit
          className={style.zoom}
        >
          {img?.url ? (
            <img
              className="full-height"
              src={img}
              objectFit={"cover"}
              objectPosition={imgPosition}
              style={{
                gridArea: "1/1",
                height: height,
                width: "auto",
              }}
              alt=""
            />
          ) : (
            <>
              <GatsbyImage
                image={img}
                className="full-height d-none d-md-block"
                objectFit={"cover"}
                objectPosition={imgPosition}
                style={{
                  gridArea: "1/1",
                  maxHeight: height,
                }}
                layout="fullWidth"
                aspectratio={3 / 1}
                alt=""
                formats={["auto", "webp", "avif"]}
                  />
              <GatsbyImage
                image={imgRespo}
                className="full-height d-block d-md-none"
                objectFit={"cover"}
                objectPosition={imgPositionRespo}
                style={{
                  gridArea: "1/1",
                  maxHeight: height,
                }}
                layout="fullWidth"
                aspectratio={3 / 1}
                alt=""
                formats={["auto", "webp", "avif"]}
              />
            </>
          )}
          
        </CSSTransition>
        {(title || subheading) && (
          <div className={`container ${style.container}`}>
            <div className={`${style.headerText}`}>
              {title && (
                <h1>{title.split(' ').map(item => (
                  <span key={item}>{item + ' '}</span>
                ))}</h1>
              )}
              {subheading && (
                <h2>
                  {subheading}
                </h2>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imgRespo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
};
