// extracted by mini-css-extract-plugin
export var call = "index-page-module--call--e634e";
export var callDescription = "index-page-module--callDescription--5450f";
export var callIcon = "index-page-module--callIcon--bc203";
export var callNumber = "index-page-module--callNumber--0949e";
export var contactForm = "index-page-module--contactForm--4575d";
export var montage = "index-page-module--montage--4a8a9";
export var price = "index-page-module--price--8ddfc";
export var priceCheck = "index-page-module--priceCheck--21cd7";
export var priceDescription = "index-page-module--priceDescription--c47c8";
export var priceInner = "index-page-module--priceInner--68443";
export var priceList = "index-page-module--priceList--b9bc6";
export var priceListItem = "index-page-module--priceListItem--6c055";
export var pricePrice = "index-page-module--pricePrice--19aa9";
export var priceTitle = "index-page-module--priceTitle--37b60";
export var ring = "index-page-module--ring--13899";