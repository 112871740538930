import React from 'react'
import './ContactForm.css'
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState(false)
 
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus(true)
    emailjs.sendForm('service_qwg57xr', 'template_wj5rr3h', e.target, 'mwJ9SF5sxPvi4Z3l3')
      .then((result) => {
        setFormStatus(false)
      }, (error) => {
        document.getElementById("form").reset();
        setFormStatus(false)
      });
    
  }
  return (
    <div className="contact_form">
      
      <div className="row">
        <div className="col-lg-12">
          <div className="contact_form_container">
            <div className="contactform-header">Napíšte nám cez kontaktný formulár</div>

            <form onSubmit={onSubmit} id="form">
                <div className="contact_form_inputs">
                    <input type="text" id="name" className="contact_form_name input_field" placeholder="Meno" required="required" data-error="Name is required." />
                    <input type="text" id="email" className="contact_form_email input_field" placeholder="Email" required="required" data-error="Email is required." />
                    <input type="text" id="phone_number" className="contact_form_phone input_field" placeholder="Telefoné číslo" />
                </div>
                <div className="contact_form_text">
                    <textarea id="message" className="text_field contact_form_message" name="Správa" rows="4" placeholder="Správa" required="required" data-error="Please, write us a message."></textarea>
                </div>
                <div className="contact_form_button mt-3">
                <button type="submit" className={`btn btn-lg btn-custom-green ${formStatus ? 'disabled' : ''}`}>Odoslať { formStatus ? '...' : '' }</button>
                </div>
            </form>

          </div>
        </div>
      </div>
    </div>

  )
}
export default ContactForm